import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewEncapsulation,
  computed,
  inject,
  signal,
} from "@angular/core";

interface ImageViewerData {
  urls: [{ fileUrl: string; name: string }];
  activeIndex: number;
}

@Component({
  standalone: true,
  selector: "app-image-viewer",
  styleUrl: "image-viewer.component.scss",
  templateUrl: "image-viewer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: "roam-image-viewer" },
})
export class ImageViewerComponent {
  protected dialogRef = inject(DialogRef);
  #index = signal<number>(0);
  index = this.#index.asReadonly();
  readonly activePreview = computed(() => {
    return this.urls.length ? this.urls[this.index()] : null;
  });

  get urls() {
    return this.data.urls;
  }

  readonly prev = (): void => {
    const currentIndex = this.index();
    const newIndex = currentIndex > 0 ? currentIndex - 1 : this.urls.length - 1;
    this.#index.set(newIndex);
  };

  readonly next = (): void => {
    const currentIndex = this.index();
    const newIndex = currentIndex < this.urls.length - 1 ? currentIndex + 1 : 0;
    this.#index.set(newIndex);
  };

  constructor(@Inject(DIALOG_DATA) public data: ImageViewerData) {
    if (typeof data.activeIndex === "number") {
      this.#index.set(data.activeIndex);
    }
  }
}
