import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  computed,
  inject,
  input,
  output,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter, fromEvent, merge, tap, throttleTime } from "rxjs";

@Directive({
  standalone: true,
  selector: "[roamDropZone]",
  host: {
    "[class]": "zoneClass()",
  },
})
export class DropZoneDirective implements AfterViewInit {
  #destroyRef = inject(DestroyRef);
  protected host = inject<ElementRef<HTMLElement>>(ElementRef);
  protected fileOver = signal(false);
  readonly fileOverClass = input<string>("");
  readonly fileDropped = output<File[]>();
  protected zoneClass = computed(() => {
    return this.fileOver() ? this.fileOverClass() : "";
  });

  get dom() {
    return this.host.nativeElement;
  }

  ngAfterViewInit(): void {
    const dragLeave$ = fromEvent<DragEvent>(this.dom, "dragleave").pipe(
      tap(e => {
        e.preventDefault();
        e.stopPropagation();
        // console.log("DRAG_LEAVE");
        this.fileOver.set(false);
      })
    );

    const dragOver$ = fromEvent<DragEvent>(this.dom, "dragover").pipe(
      tap(e => {
        e.preventDefault();
        e.stopPropagation();
      }),
      throttleTime(500),
      tap(e => {
        console.warn("DRAG_OVER", e);
        this.fileOver.set(true);
      })
    );

    const drop$ = fromEvent<DragEvent>(this.dom, "drop").pipe(
      tap(e => {
        e.preventDefault();
        e.stopPropagation();
      }),
      filter(e => Boolean(e.dataTransfer?.files.length)),
      tap(e => {
        // console.log("file dropped");
        this.fileOver.set(false);
        const fileList = e.dataTransfer?.files || [];
        if (fileList.length) {
          this.fileDropped.emit([...(fileList as File[])]);
        }
      })
    );

    merge(dragOver$, dragLeave$, drop$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }
}
