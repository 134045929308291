import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  input,
  output,
  signal,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: "app-file-image-preview",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // TODO: make a standalone style for this
  styleUrl: "./file-uploader.component.scss", 
  host: { class: "preview-card card-image" },
  imports: [MatButtonModule, MatIconModule],
  template: `
    @if(isClose()) {
      <button
        type="button"
        mat-icon-button
        class="preview-close-button"
        (click)="clickClose.emit()">
        <mat-icon class="preview-close-icon">close</mat-icon>
      </button>
    }
    <div (click)="clickView.emit()" class="hover-overlay"></div>
    <div class="image-wrapper">
      <img [src]="url()" alt="preview" />
    </div>
  `,
})
export class FileImagePreviewComponent implements OnInit {
  readonly src = input.required<File | string>();
  readonly url = signal<string>("");
  readonly clickClose = output<void>();
  readonly clickView = output<void>();
  readonly isClose = input.required<boolean>()

  ngOnInit(): void {
    const src = this.src();
    if (src instanceof File) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(src);
      fileReader.onload = () => {
        if (fileReader.result) {
          this.url.set(fileReader.result.toString());
        }
      };
    } else {
      this.url.set(src);
    }
  }
}
