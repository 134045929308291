export const FILE_EXTENSIONS = {
    IMAGE: ["jpg", "jpeg", "png", "gif"],
    DOCUMENT: ["pdf", "md", "docx", "xlsx"],
};

export const allowedExtensions: string[] = ['pdf','jpg', 'jpeg', 'png', 'md', 'docx', 'xlsx'];

export const validateFile = (file: File, extensions: string[] = allowedExtensions): boolean =>{
    const isExtensionValid = validateExtension(file?.name || "", extensions);
    const isSizeValid = validateSize(file.size);
    return isExtensionValid && isSizeValid;
}

export const validateExtension = (fileName: string, extensions: string[] = allowedExtensions): boolean => {
  const extension = fileName.split('.')[1];
    const allowedExtension: string[] = extensions; 
    
    return allowedExtension.includes(extension);
}

export const validateSize = (size: number) : boolean => {
    const maxFileSize = 1 * 1024 * 1024 * 1024;

    return size <= maxFileSize;
}
